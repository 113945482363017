import React from 'react';
import { Player } from '@lottiefiles/react-lottie-player';
import pilasLogo from '../../../../src/imagens/logos/PILASFOLIO.svg'
import { useRef } from 'react';
import styles from './styles.module.css'

export default function SplashScreen() {
    const playerRef = useRef(null);

    return (
        <div className={styles.container}>
            <div className={styles.divInterna}>
                <Player
                    ref={playerRef}
                    autoplay={true}
                    loop={true}
                    controls={false}
                    src={require("./Animacoes/pilasSplashAnimation.json")}
                    style={{ height: '300px', width: '300px' }}
                >
                </Player>
                <img src={pilasLogo} />
            </div>
        </div>
    )
}
import React from 'react';
import './custom.css'
import SplashScreen from './components/Shared/SplashScreen';

export default function App(props) {
    return (
        <>
            <SplashScreen />
        </>
    );
}
